import { useState, useEffect } from "react";
import "./styles/styles.css";
import styled from "styled-components";
import GlobalStyle from "./styles/fonts/fonts";
import GlobalFonts from "./styles/fonts/fonts";
import {
  Layout,
  PageHeader,
  Button,
  BackTop,
  Tabs,
  Row,
  Col,
  List,
  Typography,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  UpOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import DPDialog from "material-ui/DatePicker";
import { addDays } from "material-ui/DatePicker/dateUtils";
import { toClass } from "recompose";
import News from "./components/News";
import AggContents from "./components/AggContents";
import ChartKospi from "./components/ChartKospi";
import ChartKosdaq from "./components/ChartKosdaq";
import { useMediaQuery } from "react-responsive";
import AWS from "aws-sdk";
import ReactGA from "react-ga";
import ReactWordcloud from "react-wordcloud";
import { configureAnchors } from "react-scrollable-anchor";

AWS.config.region = "ap-northeast-2";
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "ap-northeast-2:3118f9c7-9559-462f-b791-117fb9135afe",
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "chartandnews" },
});

const { Content, Header, Footer } = Layout;
const { TabPane } = Tabs;
const { Text } = Typography;

configureAnchors({ offset: -87, scrollDuration: 200 });

const AppbarTitle = styled.h1`
  font-size: 12pt;
  color: black;
  font-family: MapoDPPA;
`;

const TabTitle = styled.h5`
  color: black;
  font-family: S_CoreDream_4Regular;
`;

declare global {
  interface Window {
    elem: any;
  }
}

function sendToGa(category: string, action: string, label: string) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

function formatHour(date, n) {
  let d = new Date(date);
  const hour = d.getHours() - n;
  const hourStr = hour.toString();

  if (hourStr.length < 2) {
    const twodigitHourStr = "0" + hourStr;
    return twodigitHourStr;
  } else {
    return hourStr;
  }
}

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const options: any = {
  // colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
  deterministic: true,
  enableTooltip: true,
  fontFamily: "S_CoreDream_5Medium",
  fontSizes: [8, 50],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 3,
  // randomSeed: "",
  rotationAngles: [0, 0],
  rotations: 0,
  // scale: "sqrt",
  // spiral: "archimedean",
  // svgAttributes: "",
  // textAttributes: "",
  // tooltipOptions: ,
  transitionDuration: 300,
};

function App() {
  const [date, setDate] = useState(formatDate(new Date()));
  const [hour, setHour] = useState(formatHour(new Date(), 0));
  const [chartOn, setChartOn] = useState(false);
  const [words, setWords] = useState<any>([]);
  const [top1Top5Words, setTop1Top5Words] = useState<any>([]);
  const [top6Top10Words, setTop6Top10Words] = useState<any>([]);
  const [clickedKeyword, setClickedKeyword] = useState<any>();
  const [isContentEmpty, setIsContentEmpty] = useState<boolean>(false);

  const minimumDate = new Date("2021-04-27");
  const maximumDate = new Date();

  const isExtraLargeDevices = useMediaQuery({ query: "(min-width: 1200px)" });
  const isLargeDevices = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1199px)",
  });
  const isSmallDevices = useMediaQuery({
    query: "(min-width: 576px) and (max-width: 767px)",
  });
  const isExtraSmallDevices = useMediaQuery({ query: "(max-width: 575px)" });

  const Dialog = () => (
    <DPDialog
      id="dialog"
      ref={(c) => (window.elem = c)}
      style={{ display: "none" }}
      minDate={minimumDate}
      maxDate={maximumDate}
      onChange={function dateSelect(e, c) {
        setClickedKeyword(null);
        setDate(formatDate(c));
      }}
      onClick={function onClickDialog() {
        sendToGa("날짜 네비게이션", "캘린더 팝업 내 클릭", "DPDialog");
      }}
      defaultDate={new Date()}
    />
  );

  const EDialog = toClass(Dialog);

  const onClickExactDay = (selectdate: string) => {
    setClickedKeyword(null);
    setDate(formatDate(new Date(selectdate)));
    sendToGa(
      "차트",
      "차트 플롯 클릭 / " + formatDate(new Date(selectdate)),
      "onClickExactDay"
    );
  };

  const onClickMaxDay = () => {
    setClickedKeyword(null);
    setDate(formatDate(new Date()));
    sendToGa("날짜 네비게이션", "오늘로", "onClickMaxDay");
  };

  const onClickMinusDay = () => {
    setClickedKeyword(null);
    setDate(formatDate(addDays(new Date(date), -1)));
    sendToGa("날짜 네비게이션", "-1일", "onClickMinusDay");
  };

  const onClickPlusDay = () => {
    setClickedKeyword(null);
    setDate(formatDate(addDays(new Date(date), 1)));
    sendToGa("날짜 네비게이션", "+1일", "onClickPlusDay");
  };

  const displayChart = () => {
    if (chartOn) {
      setChartOn(false);
    } else {
      setChartOn(true);
    }
  };

  const isContentEmptyHandle = () => {
    if (isContentEmpty) {
      setIsContentEmpty(false);
    } else {
      setIsContentEmpty(true);
    }
  };

  async function getWordsS3() {
    if (new Date(date) < new Date("2021-04-26")) {
      try {
        const S3Params = {
          Bucket: "chartandnews",
          Key: "content/keyword/keyword_" + date + ".json",
        };
        const S3Data: any = (await s3.getObject(S3Params).promise()).Body;
        const S3Result = JSON.parse(S3Data);
        return S3Result;
      } catch (e) {
        throw new Error(`Could not retrieve file from S3: ${e.message}`);
      }
    } else {
      try {
        const S3Params = {
          Bucket: "chartandnews",
          Key: "content/keyword/keyword_" + date + "-" + hour + ".json",
        };
        const S3Data: any = (await s3.getObject(S3Params).promise()).Body;
        const S3Result = JSON.parse(S3Data);
        return S3Result;
      } catch (e) {
        // throw new Error(`Could not retrieve file from S3: ${e.message}`);
        const S3Params = {
          Bucket: "chartandnews",
          Key:
            "content/keyword/keyword_" +
            date +
            "-" +
            formatHour(new Date(), 1) +
            ".json",
        };
        const S3Data: any = (await s3.getObject(S3Params).promise()).Body;
        const S3Result = JSON.parse(S3Data);
        return S3Result;
      }
    }
  }

  function sortTopWords(words: any, start: Number, end: Number) {
    const wordRanking = words.sort(function (a, b) {
      return a.value > b.value ? -1 : a.value < b.value ? 1 : 0;
    });
    const topNWords = wordRanking.slice(start, end);
    return topNWords;
  }

  function onClickKeyword(keyword) {
    if (clickedKeyword == keyword) {
      setClickedKeyword(null);
    } else {
      setClickedKeyword(keyword);
    }
  }

  function viewAllBtnClick() {
    setClickedKeyword(null);
  }

  useEffect(() => {
    getWordsS3().then((result) => {
      setWords(result);
      setTop1Top5Words(sortTopWords(result, 0, 5));
      setTop6Top10Words(sortTopWords(result, 5, 10));
    });
  }, [date]);

  return (
    <>
      <GlobalStyle />
      <GlobalFonts />
      <Layout className="layout">
        <div className="site-page-header-ghost-wrapper">
          <Header style={{ position: "fixed", zIndex: 100, width: "100%" }}>
            <AppbarTitle>
              <PageHeader
                ghost={true}
                title="DAILY SCRAP"
                extra={[
                  <div style={{ height: "32px", lineHeight: "32px" }}>
                    {date === formatDate(minimumDate) ? (
                      <Button
                        size="small"
                        disabled
                        icon={<LeftOutlined />}
                        onClick={() => onClickMinusDay()}
                      />
                    ) : (
                      <Button
                        size="small"
                        icon={<LeftOutlined />}
                        onClick={() => onClickMinusDay()}
                      />
                    )}
                    <MuiThemeProvider>
                      <EDialog />
                    </MuiThemeProvider>
                    <Button
                      size="small"
                      type="text"
                      onClick={() => window.elem.openDialog()}
                    >
                      {date}
                    </Button>
                    {date === formatDate(maximumDate) ? (
                      <Button
                        size="small"
                        disabled
                        icon={<RightOutlined />}
                        onClick={() => onClickPlusDay()}
                      />
                    ) : (
                      <Button
                        size="small"
                        icon={<RightOutlined />}
                        onClick={() => onClickPlusDay()}
                      />
                    )}
                    {date === formatDate(maximumDate) ? (
                      <Button
                        size="small"
                        disabled
                        icon={<DoubleRightOutlined />}
                      />
                    ) : (
                      <Button
                        size="small"
                        icon={<DoubleRightOutlined />}
                        onClick={() => onClickMaxDay()}
                      />
                    )}
                  </div>,
                ]}
              />
            </AppbarTitle>
          </Header>
        </div>
        <Row
          className="content"
          style={{
            marginTop: 74,
          }}
        >
          <Content>
            {chartOn ? (
              <div
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                  marginBottom: 20,
                }}
              >
                <div className="chart-card">
                  <TabTitle>
                    <Tabs
                      defaultActiveKey="1"
                      type="card"
                      size="small"
                      tabBarExtraContent={
                        <Button
                          onClick={function () {
                            displayChart();
                            sendToGa("차트", "차트 닫기 클릭", "");
                          }}
                          size="large"
                          type="text"
                        >
                          <CloseOutlined />
                        </Button>
                      }
                    >
                      <TabPane tab="KOSPI" key="1">
                        <ChartKospi onClickExactDay={onClickExactDay} />
                      </TabPane>
                      <TabPane tab="KOSDAQ" key="2">
                        <ChartKosdaq onClickExactDay={onClickExactDay} />
                      </TabPane>
                    </Tabs>
                  </TabTitle>
                </div>
              </div>
            ) : (
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: 20,
                  display: "flex",
                  flexGrow: 1,
                  flexBasis: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    flexBasis: "auto",
                  }}
                >
                  <Button
                    onClick={function () {
                      displayChart();
                      sendToGa("차트", "차트 열기 클릭", "");
                    }}
                    type="primary"
                    size="large"
                    block
                  >
                    차트
                  </Button>
                </div>
              </div>
            )}
          </Content>
        </Row>
        <Row gutter={0}>
          {isExtraLargeDevices && (
            <>
              <Col span={12}>
                <Content
                  style={{
                    alignContent: "center",
                    marginTop: 0,
                    marginBottom: 20,
                    marginLeft: 20,
                    marginRight: 10,
                  }}
                >
                  {words.length > 0 && (
                    <>
                      <List
                        itemLayout="horizontal"
                        header={
                          <>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <div style={{ fontSize: "16px" }}>
                                  키워드 Top10
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {date == formatDate(new Date()) ? (
                                  <div style={{ fontSize: "12px" }}>
                                    {hour}:00 기준
                                  </div>
                                ) : (
                                  <div style={{ fontSize: "12px" }}>
                                    23:00 기준
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        }
                        bordered
                      >
                        <Row>
                          <Col span={12}>
                            {top1Top5Words.map((t: any, index) => (
                              <List.Item key={index}>
                                <List.Item.Meta
                                  title={
                                    <a
                                      // href="#newshead"
                                      onClick={() => onClickKeyword(t.text)}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {clickedKeyword == t.text ? (
                                        <mark
                                          style={{
                                            backgroundColor: "#1890FF",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`${index + 1}` + ". " + `${t.text}`}
                                        </mark>
                                      ) : (
                                        `${index + 1}` + ". " + `${t.text}`
                                      )}
                                    </a>
                                  }
                                />
                              </List.Item>
                            ))}
                          </Col>
                          <Col span={12}>
                            {top6Top10Words.map((t: any, index) => (
                              <List.Item key={index}>
                                <List.Item.Meta
                                  title={
                                    <a
                                      // href="#newshead"
                                      onClick={() => onClickKeyword(t.text)}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {clickedKeyword == t.text ? (
                                        <mark
                                          style={{
                                            backgroundColor: "#1890FF",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`${index + 6}` + ". " + `${t.text}`}
                                        </mark>
                                      ) : (
                                        `${index + 6}` + ". " + `${t.text}`
                                      )}
                                    </a>
                                  }
                                />
                              </List.Item>
                            ))}
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col span={24}>
                            <div
                              style={{
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 5,
                                paddingBottom: 5,
                              }}
                            >
                              <Slider
                                range={false}
                                min={0}
                                max={23}
                                included={false}
                                marks={{
                                  0: "0시",
                                  1: "1시",
                                  2: "2시",
                                  3: "3시",
                                  4: "4시",
                                  5: "5시",
                                  6: "6시",
                                  7: "7시",
                                  8: "8시",
                                  9: "9시",
                                  10: "10시",
                                  11: "11시",
                                  12: "12시",
                                  13: "13시",
                                  14: "14시",
                                  15: "15시",
                                  16: "16시",
                                  17: "17시",
                                  18: "18시",
                                  19: "19시",
                                  20: "20시",
                                  21: "21시",
                                  22: "22시",
                                  23: "23시",
                                }}
                                step={null}
                                defaultValue={0}
                              />
                            </div>
                          </Col>
                        </Row> */}
                      </List>
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                          borderLeft: "1px solid #f0f0f0",
                          borderRight: "1px solid #f0f0f0",
                          borderBottom: "1px solid #f0f0f0",
                          padding: 3,
                        }}
                      >
                        <ReactWordcloud options={options} words={words} />
                      </div>
                    </>
                  )}
                  <div>
                    <News
                      date={date}
                      hour={hour}
                      clickedKeyword={clickedKeyword}
                      viewAllBtnClick={viewAllBtnClick}
                    />
                  </div>
                </Content>
              </Col>
              <Col span={12}>
                <Content
                  style={{
                    alignContent: "center",
                    marginTop: 0,
                    marginBottom: 20,
                    marginLeft: 10,
                    marginRight: 20,
                  }}
                >
                  <AggContents
                    date={date}
                    cardSpan={12}
                    isContentEmpty={isContentEmptyHandle}
                  />
                </Content>
              </Col>
            </>
          )}
          {isLargeDevices && (
            <>
              <Col span={14}>
                <Content
                  style={{
                    alignContent: "center",
                    marginTop: 0,
                    marginBottom: 20,
                    marginLeft: 20,
                    marginRight: 10,
                  }}
                >
                  {words.length > 0 && (
                    <>
                      <List
                        itemLayout="horizontal"
                        header={
                          <>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <div style={{ fontSize: "16px" }}>
                                  키워드 Top10
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {date == formatDate(new Date()) ? (
                                  <div style={{ fontSize: "12px" }}>
                                    {hour}:00 기준
                                  </div>
                                ) : (
                                  <div style={{ fontSize: "12px" }}>
                                    23:00 기준
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        }
                        bordered
                      >
                        <Row>
                          <Col span={12}>
                            {top1Top5Words.map((t: any, index) => (
                              <List.Item key={index}>
                                <List.Item.Meta
                                  title={
                                    <a
                                      // href="#newshead"
                                      onClick={() => onClickKeyword(t.text)}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {clickedKeyword == t.text ? (
                                        <mark
                                          style={{
                                            backgroundColor: "#1890FF",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`${index + 1}` + ". " + `${t.text}`}
                                        </mark>
                                      ) : (
                                        `${index + 1}` + ". " + `${t.text}`
                                      )}
                                    </a>
                                  }
                                />
                              </List.Item>
                            ))}
                          </Col>
                          <Col span={12}>
                            {top6Top10Words.map((t: any, index) => (
                              <List.Item key={index}>
                                <List.Item.Meta
                                  title={
                                    <a
                                      // href="#newshead"
                                      onClick={() => onClickKeyword(t.text)}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {clickedKeyword == t.text ? (
                                        <mark
                                          style={{
                                            backgroundColor: "#1890FF",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`${index + 6}` + ". " + `${t.text}`}
                                        </mark>
                                      ) : (
                                        `${index + 6}` + ". " + `${t.text}`
                                      )}
                                    </a>
                                  }
                                />
                              </List.Item>
                            ))}
                          </Col>
                        </Row>
                      </List>
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                          borderLeft: "1px solid #f0f0f0",
                          borderRight: "1px solid #f0f0f0",
                          borderBottom: "1px solid #f0f0f0",
                          padding: 3,
                        }}
                      >
                        <ReactWordcloud options={options} words={words} />
                      </div>
                    </>
                  )}
                  <div>
                    <News
                      date={date}
                      hour={hour}
                      clickedKeyword={clickedKeyword}
                      viewAllBtnClick={viewAllBtnClick}
                    />
                  </div>
                </Content>
              </Col>
              <Col span={10}>
                <Content
                  style={{
                    alignContent: "center",
                    marginTop: 0,
                    marginBottom: 20,
                    marginLeft: 10,
                    marginRight: 20,
                  }}
                >
                  <AggContents
                    date={date}
                    cardSpan={24}
                    isContentEmpty={isContentEmptyHandle}
                  />
                </Content>
              </Col>
            </>
          )}
          {isSmallDevices && (
            <>
              <Col span={12}>
                <Content
                  style={{
                    alignContent: "center",
                    marginTop: 0,
                    marginBottom: 20,
                    marginLeft: 20,
                    marginRight: 10,
                  }}
                >
                  {words.length > 0 && (
                    <>
                      <List
                        itemLayout="horizontal"
                        header={
                          <>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <div style={{ fontSize: "16px" }}>
                                  키워드 Top10
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {date == formatDate(new Date()) ? (
                                  <div style={{ fontSize: "12px" }}>
                                    {hour}:00 기준
                                  </div>
                                ) : (
                                  <div style={{ fontSize: "12px" }}>
                                    23:00 기준
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        }
                        bordered
                      >
                        <Row>
                          <Col span={12}>
                            {top1Top5Words.map((t: any, index) => (
                              <List.Item key={index}>
                                <List.Item.Meta
                                  title={
                                    <a
                                      // href="#newshead"
                                      onClick={() => onClickKeyword(t.text)}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {clickedKeyword == t.text ? (
                                        <mark
                                          style={{
                                            backgroundColor: "#1890FF",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`${index + 1}` + ". " + `${t.text}`}
                                        </mark>
                                      ) : (
                                        `${index + 1}` + ". " + `${t.text}`
                                      )}
                                    </a>
                                  }
                                />
                              </List.Item>
                            ))}
                          </Col>
                          <Col span={12}>
                            {top6Top10Words.map((t: any, index) => (
                              <List.Item key={index}>
                                <List.Item.Meta
                                  title={
                                    <a
                                      // href="#newshead"
                                      onClick={() => onClickKeyword(t.text)}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {clickedKeyword == t.text ? (
                                        <mark
                                          style={{
                                            backgroundColor: "#1890FF",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`${index + 6}` + ". " + `${t.text}`}
                                        </mark>
                                      ) : (
                                        `${index + 6}` + ". " + `${t.text}`
                                      )}
                                    </a>
                                  }
                                />
                              </List.Item>
                            ))}
                          </Col>
                        </Row>
                      </List>
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                          borderLeft: "1px solid #f0f0f0",
                          borderRight: "1px solid #f0f0f0",
                          borderBottom: "1px solid #f0f0f0",
                          padding: 3,
                        }}
                      >
                        <ReactWordcloud options={options} words={words} />
                      </div>
                    </>
                  )}
                  <div>
                    <News
                      date={date}
                      hour={hour}
                      clickedKeyword={clickedKeyword}
                      viewAllBtnClick={viewAllBtnClick}
                    />
                  </div>
                </Content>
              </Col>
              <Col span={12}>
                <Content
                  style={{
                    alignContent: "center",
                    marginTop: 0,
                    marginBottom: 20,
                    marginLeft: 10,
                    marginRight: 20,
                  }}
                >
                  <AggContents
                    date={date}
                    cardSpan={24}
                    isContentEmpty={isContentEmptyHandle}
                  />
                </Content>
              </Col>
            </>
          )}
          {isExtraSmallDevices && (
            <Col span={24}>
              <Content
                style={{
                  alignContent: "center",
                  marginTop: 0,
                  marginBottom: 20,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                {words.length > 0 && (
                  <>
                    <List
                      itemLayout="horizontal"
                      header={
                        <>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div style={{ fontSize: "16px" }}>
                                키워드 Top10
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                justifyContent: "flex-end",
                              }}
                            >
                              {date == formatDate(new Date()) ? (
                                <div style={{ fontSize: "12px" }}>
                                  {hour}:00 기준
                                </div>
                              ) : (
                                <div style={{ fontSize: "12px" }}>
                                  23:00 기준
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                      bordered
                    >
                      <Row>
                        <Col span={12}>
                          {top1Top5Words.map((t: any, index) => (
                            <List.Item key={index}>
                              <List.Item.Meta
                                title={
                                  <a
                                    // href="#newshead"
                                    onClick={() => onClickKeyword(t.text)}
                                    style={{ fontSize: "16px" }}
                                  >
                                    {clickedKeyword == t.text ? (
                                      <mark
                                        style={{
                                          backgroundColor: "#1890FF",
                                          color: "white",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {`${index + 1}` + ". " + `${t.text}`}
                                      </mark>
                                    ) : (
                                      `${index + 1}` + ". " + `${t.text}`
                                    )}
                                  </a>
                                }
                              />
                            </List.Item>
                          ))}
                        </Col>
                        <Col span={12}>
                          {top6Top10Words.map((t: any, index) => (
                            <List.Item key={index}>
                              <List.Item.Meta
                                title={
                                  <a
                                    // href="#newshead"
                                    onClick={() => onClickKeyword(t.text)}
                                    style={{ fontSize: "16px" }}
                                  >
                                    {clickedKeyword == t.text ? (
                                      <mark
                                        style={{
                                          backgroundColor: "#1890FF",
                                          color: "white",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {`${index + 6}` + ". " + `${t.text}`}
                                      </mark>
                                    ) : (
                                      `${index + 6}` + ". " + `${t.text}`
                                    )}
                                  </a>
                                }
                              />
                            </List.Item>
                          ))}
                        </Col>
                      </Row>
                    </List>
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 20,
                        borderLeft: "1px solid #f0f0f0",
                        borderRight: "1px solid #f0f0f0",
                        borderBottom: "1px solid #f0f0f0",
                        padding: 3,
                      }}
                    >
                      <ReactWordcloud options={options} words={words} />
                    </div>
                  </>
                )}
                <div>
                  <News
                    date={date}
                    hour={hour}
                    clickedKeyword={clickedKeyword}
                    viewAllBtnClick={viewAllBtnClick}
                  />
                </div>
                <AggContents
                  date={date}
                  cardSpan={24}
                  isContentEmpty={isContentEmptyHandle}
                />
              </Content>
            </Col>
          )}
        </Row>
        <BackTop>
          <div
            style={{
              height: 40,
              width: 40,
              lineHeight: "40px",
              borderRadius: 4,
              backgroundColor: "black",
              color: "white",
              textAlign: "center",
              fontSize: 14,
            }}
          >
            <UpOutlined />
          </div>
        </BackTop>
        <Footer className="footer" style={{ textAlign: "center" }}>
          <Text disabled>
            도움이 되셨나요?
            <br></br>
            사소한 피드백도 큰 도움이 됩니다.
          </Text>
          <br></br>
          <Button
            onClick={function () {
              sendToGa("피드백", "피드백 보내기 버튼 클릭", "");
              window.open("https://forms.gle/Q7n7RwDZimqGKoXs8");
            }}
            type="ghost"
            size="middle"
            shape="round"
          >
            <Text type="secondary">피드백 제공하기</Text>
          </Button>
          <br></br>
          <br></br>
          DAILY SCRAP ©2022
        </Footer>
      </Layout>
    </>
  );
}

export default App;
