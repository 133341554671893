import { useState, useEffect } from "react";
import "../styles/chartStyles.css";
import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import AWS from "aws-sdk";
import ReactGA from "react-ga";

AWS.config.region = "ap-northeast-2";
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "ap-northeast-2:3118f9c7-9559-462f-b791-117fb9135afe",
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "chartandnews" },
});

const ChartData = styled.h5`
  color: black;
  font-family: S_CoreDream_5Medium;
  font-size: 12px;
  margin-bottom: 0;
`;

function sendToGa(category: string, action: string, label: string) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

function ChartKospi({ onClickExactDay }) {
  const [kospiData, setKospiData] = useState([]);
  const [hoverTooltip, setHoverTooltip] = useState<string>();

  async function getKospi() {
    try {
      const S3Params = {
        Bucket: "chartandnews",
        Key: "chart/kospi/kospi_2020.json",
      };
      const S3Data: any = (await s3.getObject(S3Params).promise()).Body;
      const S3Result = JSON.parse(S3Data);
      return S3Result;
    } catch (e) {
      throw new Error(`Could not retrieve file from S3: ${e.message}`);
    }
  }

  useEffect(() => {
    getKospi().then((result) => setKospiData(result));
  }, []);

  function formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function highchartsDate(date) {
    const dateSTR = date.split("-");
    const dateINT = dateSTR.map((e) => parseInt(e));
    const xDate = Date.UTC(dateINT[0], dateINT[1] - 1, dateINT[2]);
    return xDate;
  }

  const closeArray: any = [];
  const rangeArray: any = [];
  const volumeArray: any = [];

  for (let dateStr of Object.keys(kospiData)) {
    const dailyClose = [
      highchartsDate(dateStr),
      kospiData[dateStr][0]["close"],
    ];
    const dailyRange = [
      highchartsDate(dateStr),
      kospiData[dateStr][0]["high"],
      kospiData[dateStr][0]["low"],
    ];
    const dailyVolume = [
      highchartsDate(dateStr),
      kospiData[dateStr][0]["volume"],
    ];
    closeArray.push(dailyClose);
    rangeArray.push(dailyRange);
    volumeArray.push(dailyVolume);
  }

  const chartsOptions = {
    title: { text: "" },
    chart: {
      height: 270,
      // events: {
      //   load(this: any) {
      //     this.series.points[1].select();
      //   },
      // },
    },
    legend: { enabled: false },
    tooltip: { enabled: false },
    xAxis: {
      type: "datetime",
      clickOnCrosshair: true,
      tickInterval: 30 * 24 * 3600 * 1000,
      range: 100 * 24 * 3600 * 1000,
      labels: {
        enabled: true,
        align: "center",
        format: "{value:%y년%m월}",
      },
      crosshair: {
        width: 40,
        color: "#f3f3f3",
        snap: true,
        zIndex: 0,
      },
      startOnTick: false,
      endOnTick: false,
      minPadding: 0,
      maxPadding: 0,
      offset: 0,
      lineWidth: 0,
      minorTickLength: 0,
      tickWidth: 0,
    },
    yAxis: {
      visible: true,
      crosshair: true,
      gridLineWidth: 1,
      gridLineDashStyle: "Dash",
      showLastLabel: true,
      title: "",
    },
    plotOptions: {
      series: {
        color: "#007fff",
        lineWidth: 3,
        cursor: "pointer",
        allowPointSelect: true,
        animation: false,
        marker: {
          enabled: false,
          enabledThreshold: 0,
          radius: 3,
          // fillColor: "white",
          // lineColor: "#3f51b5",
          // lineWidth: 0,
          // states: {
          //   hover: {
          //     halo: null,
          //     radiusPlus: 0,
          //     // lineWidthPlus: 3,
          //     // fillColor: "#3f51b5",
          //     // lineColor: "white",
          //   },
          //   // select: {
          //   //   radiusPlus: 0,
          //   //   // lineWidthPlus: 3,
          //   //   // fillColor: "white",
          //   //   // lineColor: "black",
          //   // },
          // },
        },
        point: {
          events: {
            mouseOver: function (this: any) {
              setHoverTooltip(formatDate(this.x) + ", " + this.y);
              sendToGa(
                "차트",
                "KOSPI 마커 마우스오버",
                "ChartKospi > chartsOptions"
              );
            },
            click: function (this: any) {
              onClickExactDay(formatDate(this.x));
              sendToGa("차트", "KOSPI 마커 클릭", "ChartKospi > chartsOptions");
            },
          },
        },
      },
    },
    series: {
      name: "코스피",
      type: "area",
      data: closeArray,
      zIndex: 3,
    },
  };

  return (
    <>
      {hoverTooltip ? (
        <ChartData>
          <div style={{ textAlign: "center" }}>{hoverTooltip}</div>
        </ChartData>
      ) : (
        <ChartData>
          <div style={{ textAlign: "center" }}>날짜를 선택하면 이동합니다</div>
        </ChartData>
      )}
      <HighchartsReact highcharts={Highcharts} options={chartsOptions} />
    </>
  );
}

export default ChartKospi;
