import { useState, useEffect } from "react";
import { Card, Skeleton, Row, Col } from "antd";
import AWS from "aws-sdk";
import ReactGA from "react-ga";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination]);

AWS.config.region = "ap-northeast-2";
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "ap-northeast-2:3118f9c7-9559-462f-b791-117fb9135afe",
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "chartandnews" },
});

const { Meta } = Card;

interface LoadingStatus {
  loading: boolean;
}

function sendToGa(category: string, action: string, label: string) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

function AggContents({ date, cardSpan, isContentEmpty }) {
  const [contents, setContents] = useState([]);
  const [onLoading, setOnLoading] = useState<LoadingStatus>({
    loading: false,
  });

  async function getContentsS3() {
    try {
      setOnLoading({ loading: true });

      const aggContentsArr: any = [];

      const S3ParamsArticle: any = {
        Bucket: "chartandnews",
        Key: "content/article/remembernow/remembernow_" + date + ".json",
      };

      const S3DataArticle: any = (await s3.getObject(S3ParamsArticle).promise())
        .Body;
      const S3ResultArticle = JSON.parse(S3DataArticle);
      if (S3ResultArticle[date]) {
        for (let i = 0; i < S3ResultArticle[date].length; i++) {
          aggContentsArr.push(S3ResultArticle[date][i]);
        }
        isContentEmpty(false);
      } else {
        isContentEmpty(true);
      }

      const S3ParamsEconomyhand: any = {
        Bucket: "chartandnews",
        Key: "content/audio/economyhand_" + date + ".json",
      };
      const S3DataEconomyhand: any = (
        await s3.getObject(S3ParamsEconomyhand).promise()
      ).Body;
      const S3ResultEconomyhand = JSON.parse(S3DataEconomyhand);
      if (S3ResultEconomyhand[date]) {
        for (let i = 0; i < S3ResultEconomyhand[date].length; i++) {
          aggContentsArr.push(S3ResultEconomyhand[date][i]);
        }
        isContentEmpty(false);
      } else {
        isContentEmpty(true);
      }

      const S3ParamsYouTube: any = {
        Bucket: "chartandnews",
        Key: "content/youtube/youtube_" + date + ".json",
      };
      const S3DataYouTube: any = (await s3.getObject(S3ParamsYouTube).promise())
        .Body;
      const S3ResultYouTube = JSON.parse(S3DataYouTube);
      if (S3ResultYouTube[date]) {
        for (let i = 0; i < S3ResultYouTube[date].length; i++) {
          aggContentsArr.push(S3ResultYouTube[date][i]);
        }
        isContentEmpty(false);
      } else {
        isContentEmpty(true);
      }
      return aggContentsArr;
    } catch (e) {
      throw new Error(`Could not retrieve file from S3: ${e.message}`);
    }
  }

  function coverImage(content) {
    if (onLoading.loading) {
      return <Skeleton.Image />;
    } else {
      return (
        <>
          {content.thumbnailURL ? (
            // <Swiper
            //   slidesPerView={1}
            //   navigation
            //   pagination={{ clickable: true }}
            // >
            //   <SwiperSlide>
            <img
              style={{
                width: "100%",
                border: "1px solid",
                borderColor: "#f0f0f0",
              }}
              alt={content.title}
              src={content.thumbnailURL}
            />
          ) : (
            //   </SwiperSlide>
            //   <SwiperSlide>
            //     <div>여기엔 요약된 내용이 들어갑니다!</div>
            //   </SwiperSlide>
            // </Swiper>
            // <Swiper
            //   slidesPerView={1}
            //   navigation
            //   pagination={{ clickable: true }}
            // >
            //   <SwiperSlide>
            <img
              style={{
                width: "100%",
                border: "1px solid",
                borderColor: "#f0f0f0",
              }}
              alt={content.title}
              src={content.thumbnails.url}
            />
            //   </SwiperSlide>
            //   <SwiperSlide>
            //     <div>여기엔 요약된 내용이 들어갑니다!</div>
            //   </SwiperSlide>
            // </Swiper>
          )}
        </>
      );
    }
  }

  function sourceTitle(content) {
    if (content.source) {
      return <div style={{ fontSize: "16px" }}>{content.source}</div>; // 리맴버나우
    } else if (content.channelTitle) {
      return <div style={{ fontSize: "16px" }}>{content.channelTitle}</div>; // YouTube
    } else {
      return <div style={{ fontSize: "16px" }}>이진우의 손에 잡히는 경제</div>; // 이진우의 손에 잡히는 경제
    }
  }

  useEffect(() => {
    getContentsS3().then((result) => {
      if (result) {
        setContents(result);
        setOnLoading({ loading: false });
      }
    });
  }, [date]);

  return (
    <>
      {contents.length > 0 && (
        <Row gutter={20}>
          {contents.map((d: any, index) => (
            <Col span={cardSpan}>
              <Card
                style={{ marginBottom: 20 }}
                key={index}
                type="inner"
                hoverable
                loading={onLoading.loading}
                cover={coverImage(d)}
                title={sourceTitle(d)}
                onClick={function onClickCard() {
                  window.open(`${d.url}`, "_blank");
                  sendToGa(
                    "콘텐츠",
                    "카드 클릭" + " / " + d.source + " / " + d.title,
                    ""
                  );
                }}
              >
                <a href={d.url} target="_blank">
                  <Meta title={d.title} description={d.summary} />
                </a>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}

export default AggContents;
