import { useState, useEffect } from "react";
import "../styles/styles.css";
import styled from "styled-components";
import { List, Layout, Button, Skeleton, Tooltip } from "antd";
import {
  ConsoleSqlOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import AWS from "aws-sdk";
import ReactGA from "react-ga";
import { integer } from "aws-sdk/clients/frauddetector";
import fonts from "../styles/fonts/fonts";

AWS.config.region = "ap-northeast-2";
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "ap-northeast-2:3118f9c7-9559-462f-b791-117fb9135afe",
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "chartandnews" },
});

const { Content } = Layout;

const CardNewsTitle = styled.h5`
  color: black;
  font-family: S_CoreDream_5Medium;
  font-size: 14px;
  margin-bottom: 0;
`;

const CardNewsSource = styled.h4`
  color: black;
  font-family: S_CoreDream_3Light;
  font-size: 14px;
  margin-bottom: 0;
`;

const CardContent = styled.h5`
  color: gray;
  font-family: S_CoreDream_4Regular;
  font-size: 12px;
  margin-bottom: 0;
`;

interface LoadingStatus {
  loading: boolean;
}

interface initLoadingStatus {
  initLoading: boolean;
}

interface indexStatus {
  start: integer;
  end: integer;
}

function sendToGa(category: string, action: string, label: string) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function News({ date, hour, clickedKeyword, viewAllBtnClick }) {
  const [newsFullContent, setNewsFullContent] = useState([]);
  const [newsContent, setNewsContent] = useState([]);
  const [initLoading, setInitLoading] = useState<initLoadingStatus>({
    initLoading: true,
  });
  const [onLoading, setOnLoading] = useState<LoadingStatus>({
    loading: false,
  });
  const [index, setIndex] = useState<indexStatus>({
    start: 0,
    end: 10,
  });

  async function getNewsMetaS3(key) {
    try {
      setOnLoading({ loading: true });
      const S3Params = {
        Bucket: "chartandnews",
        Key: key,
      };
      const S3Data: any = (await s3.getObject(S3Params).promise()).Body;
      const S3Result = JSON.parse(S3Data);
      return S3Result;
    } catch (e) {
      throw new Error(`Could not retrieve file from S3: ${e.message}`);
    }
  }

  async function getSearchedNewsMetaS3(key, keyword) {
    setOnLoading({ loading: true });

    const S3Params: any = {
      Bucket: "chartandnews",
      Key: key,
      ExpressionType: "SQL",
      Expression:
        "SELECT * FROM s3object[*]['" +
        date +
        "'][*] t WHERE t.title LIKE '%" +
        keyword +
        "%'",
      InputSerialization: {
        JSON: {
          Type: "DOCUMENT",
        },
      },
      OutputSerialization: {
        JSON: {
          RecordDelimiter: "++",
        },
      },
    };

    s3.selectObjectContent(S3Params, (err, data: any) => {
      try {
        const Uint8ArrayConvert = new TextDecoder().decode(
          data.Payload[0].Records.Payload
        );
        const Uint8ArraySerialize = JSON.parse(
          JSON.stringify(Uint8ArrayConvert)
        );
        const elementArr: any = [];
        let resultSplit = Uint8ArraySerialize.split("++");
        for (var i = 0; i < resultSplit.length - 1; i++) {
          let split1 = resultSplit[i].split('{"publishedAt":');
          let split2 = split1[1].split(',"source":');
          let split3 = split2[1].split(',"title":');
          let split4 = split3[1].split(',"url":');
          const publishedAt = split2[0].trim().replaceAll('"', "");
          const source = split3[0].trim().replaceAll('"', "");
          const title1 = split4[0].trim().replaceAll('"', "");
          const title = title1.trim().replaceAll("\\", "");
          const url1 = split4[1].trim().replaceAll('"', "");
          const url = url1.trim().replaceAll("}", "");
          const element = {
            publishedAt: publishedAt,
            source: source,
            title: title,
            url: url,
          };
          elementArr.push(element);
        }
        setNewsContent(elementArr);
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  useEffect(() => {
    if (clickedKeyword) {
      getSearchedNewsMetaS3(
        "content/news/stock/2021/naverpressnews_" + date + "-" + hour + ".json",
        clickedKeyword
      );
      setOnLoading({ loading: false });
      setInitLoading({ initLoading: false });
    } else {
      if (date == formatDate(new Date())) {
        getNewsMetaS3(
          "content/news/stock/2021/naverpressnews_" +
            date +
            "-" +
            hour +
            ".json"
        ).then((result) => {
          setNewsFullContent(result[date]);
          setNewsContent(result[date].slice(index.start, index.end));
          setOnLoading({ loading: false });
          setInitLoading({ initLoading: false });
        });
      } else {
        getNewsMetaS3(
          "content/news/stock/2021/naverpressnews_" + date + "-23.json"
        ).then((result) => {
          setNewsFullContent(result[date]);
          setNewsContent(result[date].slice(index.start, index.end));
          setOnLoading({ loading: false });
          setInitLoading({ initLoading: false });
        });
      }
    }
  }, [date, clickedKeyword]);

  function onLoadMore() {
    setIndex({ start: (index.start += 10), end: (index.end += 10) });

    getNewsMetaS3(
      "content/news/stock/2021/naverpressnews_" + date + "-" + hour + ".json"
    ).then((result) => {
      setNewsContent(
        newsContent.concat(result[date].slice(index.start, index.end))
      );
      setOnLoading({ loading: false });
    });
  }

  const loadMore =
    !initLoading.initLoading &&
    !onLoading.loading &&
    index.end == newsContent.length ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 10,
          height: 32,
          lineHeight: "32px ",
          marginBottom: 20,
        }}
      >
        <Button onClick={onLoadMore}>더보기</Button>
      </div>
    ) : null;

  function highlighter(sentence, keyword) {
    const splitText = sentence.split(keyword);
    const beforeKeyword = splitText[0];
    const afterKeyword = splitText[1];
    return (
      <>
        {beforeKeyword}
        <mark
          style={{
            backgroundColor: "#1890FF",
            color: "white",
            fontWeight: "bold",
          }}
        >
          {keyword}
        </mark>
        {afterKeyword}
      </>
    );
  }

  function cardDescription(item) {
    if (item.publishedAt) {
      return `${item.source}` + " " + `${item.publishedAt}`;
    } else {
      return `${item.source}`;
    }
  }

  return (
    <>
      <Content className="content">
        {newsContent.length > 0 ? (
          <List
            style={{ marginBottom: 20 }}
            loading={initLoading.initLoading}
            itemLayout="horizontal"
            loadMore={loadMore}
            header={
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ fontSize: "16px" }}>
                      뉴스{" "}
                      <Tooltip
                        placement="right"
                        title={
                          "국내 51개 언론사 경제면을 한 시간마다 스크랩합니다. 현재 " +
                          `${newsFullContent.length}` +
                          "개의 뉴스가 있습니다."
                        }
                        onVisibleChange={function popTooltip() {
                          sendToGa(
                            "뉴스",
                            "팝업 툴팁",
                            "국내 51개 언론사 경제면을 한 시간마다 스크랩합니다."
                          );
                        }}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      {clickedKeyword && (
                        <Button onClick={viewAllBtnClick} size="small">
                          <ReloadOutlined />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
            bordered
          >
            {newsContent.map((t: any, index) => (
              <List.Item key={index}>
                <Skeleton title={false} loading={onLoading.loading} active>
                  {clickedKeyword ? (
                    <List.Item.Meta
                      title={
                        <a
                          href={t.url}
                          target="_blank"
                          onClick={function onClickNews() {
                            sendToGa("뉴스", t.title, "News > List.Item.Meta");
                          }}
                          style={{ fontSize: "16px" }}
                        >
                          {highlighter(t.title, clickedKeyword)}
                        </a>
                      }
                      description={cardDescription(t)}
                    />
                  ) : (
                    <List.Item.Meta
                      title={
                        <a
                          href={t.url}
                          target="_blank"
                          onClick={function onClickNews() {
                            sendToGa("뉴스", t.title, "News > List.Item.Meta");
                          }}
                          style={{ fontSize: "16px" }}
                        >
                          {t.title}
                        </a>
                      }
                      description={cardDescription(t)}
                    />
                  )}
                </Skeleton>
              </List.Item>
            ))}
          </List>
        ) : null}
      </Content>
    </>
  );
}

export default News;
