import { createGlobalStyle } from "styled-components";
import S_CoreDream_1Thin from "./S_CoreDream_1Thin.woff";
import S_CoreDream_2ExtraLight from "./S_CoreDream_2ExtraLight.woff";
import S_CoreDream_3Light from "./S_CoreDream_3Light.woff";
import S_CoreDream_4Regular from "./S_CoreDream_4Regular.woff";
import S_CoreDream_5Medium from "./S_CoreDream_5Medium.woff";
import S_CoreDream_6Bold from "./S_CoreDream_6Bold.woff";
import S_CoreDream_7ExtraBold from "./S_CoreDream_7ExtraBold.woff";
import S_CoreDream_8Heavy from "./S_CoreDream_8Heavy.woff";
import S_CoreDream_9Black from "./S_CoreDream_9Black.woff";
import MapoDPPA from "./MapoDPPA.woff";

export default createGlobalStyle`
    @font-face {
        font-family: "S_CoreDream_1Thin";
        src: local("S_CoreDream_1Thin.woff"),
        url(${S_CoreDream_1Thin}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "S_CoreDream_2ExtraLight";
        src: local("S_CoreDream_2ExtraLight.woff"),
        url(${S_CoreDream_2ExtraLight}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "S_CoreDream_3Light";
        src: local("S_CoreDream_3Light.woff"),
        url(${S_CoreDream_3Light}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "S_CoreDream_4Regular";
        src: local("S_CoreDream_4Regular.woff"),
        url(${S_CoreDream_4Regular}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "S_CoreDream_5Medium";
        src: local("S_CoreDream_5Medium.woff"),
        url(${S_CoreDream_5Medium}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "S_CoreDream_6Bold";
        src: local("S_CoreDream_6Bold.woff"),
        url(${S_CoreDream_6Bold}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "S_CoreDream_7ExtraBold";
        src: local("S_CoreDream_7ExtraBold.woff"),
        url(${S_CoreDream_7ExtraBold}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "S_CoreDream_8Heavy";
        src: local("S_CoreDream_8Heavy.woff"),
        url(${S_CoreDream_8Heavy}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "S_CoreDream_9Black";
        src: local("S_CoreDream_9Black.woff"),
        url(${S_CoreDream_9Black}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: "MapoDPPA";
        src: local("MapoDPPA.woff"),
        url(${MapoDPPA}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
`;